export const firebaseConfig = {
  apiKey: "AIzaSyCW4mOHW3aT0K71c47gLddYlrf0ajcQqO4",
  authDomain: "raw-revolt.firebaseapp.com",
  databaseURL: "https://raw-revolt.firebaseio.com",
  projectId: "raw-revolt",
  storageBucket: "raw-revolt.appspot.com",
  messagingSenderId: "594554652403",
  appId: "1:594554652403:web:5e0cfdf1c59c4ce1689986",
  measurementId: "G-Q2GMCLCCE4"
}
