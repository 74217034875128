import React from 'react';
import '../config/styles.css';
import {firebaseConfig} from '../config/firebaseConfig'
import spinner from '../spinner.gif';
const firebase = require("firebase");
// Required for side-effects
require("firebase/firestore");

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      complete: false,
      loading: true,
      auth: false,
      password: null,
      showError: false,
      showMinLengthError: false,
      showFatalError: false,
      userId: null
    }

    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }

    this.db = firebase.firestore();
    this.resetId = (new URLSearchParams(window.location.search).get('oobCode'));
  }

  componentDidMount() {
    this.checkId();
  }

  checkId = () => {

    firebase.auth().verifyPasswordResetCode(this.resetId).then((email) => {
      // Display a "new password" form with the user's email address
      this.setState({loading: false, auth: true});
    })
    .catch(() => {
      // Invalid code
      this.setState({loading: false, auth: false});
    });
  }

  updatePassword = () => {
    if (!this.state.password) {
      this.setState({showError: true});
      return;
    }

    if (this.state.password.length < 6) {
      this.setState({showMinLengthError: true});
      return;
    }

    this.setState({loading:true, showError: false, showMinLengthError: false}, ()=> {
      firebase.auth().confirmPasswordReset(this.resetId, this.state.password)
      .then(() => {
        // Success
        this.setState({complete: true})
      })
      .catch(() => {
        // Invalid code
        this.setState({loading:true, showError: false, showMinLengthError: false, showFatalError: true});
      })
    });
  }

  render() {
    if (this.state.complete) {
      return (
        <div className="App">
          <img src="/logo-black.png" alt="Raw Revolt"/>
          <p>Your password has been changed. Log back into the app with your username and new password.</p>
        </div>
      )
    }
    if (this.state.loading) {
      return (
        <div className="App">
          <img src="/logo-black.png" alt="Raw Revolt"/>
          <div className="spinner">
            <img src={spinner} alt="Loading..."/>
          </div>
        </div>
      )
    }
    if (this.state.auth) {
      return (
        <div className="App">
          <img src="/logo-black.png" alt="Raw Revolt"/>
          <p>Enter a new password below. Once you're finished, log back into the app with your username and new password.</p>
          <form onSubmit={(e)=>{e.preventDefault(); this.updatePassword()}}>
            <input placeholder="Enter your new password here" type="password" onChange={(e) => {this.setState({password: e.target.value})}}/>
            {this.state.showError &&
              <p className="error">Your password cannot be left blank</p>
            }
            {this.state.showMinLengthError &&
              <p className="error">Your password must be at least 6 characters</p>
            }
            {this.state.showFatalError &&
              <p className="error">There was an error updating your password. Please try again</p>
            }
            <button type="submit" className="button">SET NEW PASSWORD</button>
          </form>
        </div>
      )
    } else {
      return (
        <div className="App">
          <img src="/logo-black.png" alt="Raw Revolt"/>
          <p>This password reset link is no longer valid. If you need to reset your password again, please request to do so in the app.</p>
        </div>
      )
    }
  }

}

export default Home;
